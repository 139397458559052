/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import './reset.css';
import './Layout.scss';

const Layout = ({ children }) => {
	return <React.Fragment>{children}</React.Fragment>;
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
